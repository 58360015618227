import {
  CYCLOPS_ORGANIZATIONS_DATA_LOADED,
  CYCLOPS_ORGANIZATIONS_DATA_CLEAR,
  CYCLOPS_ACTIVE_ORGANIZATION_SET,
  CYCLOPS_ACTIVE_ORGANIZATION_CLEAR,
  CYCLOPS_LIVESTREAMS_DATA_LOADED,
  CYCLOPS_LIVESTREAMS_DATA_CLEAR,
  CYCLOPS_LIVESTREAM_DETAILS_LOADED,
  CYCLOPS_LIVESTREAM_DETAILS_CLEAR,
  CYCLOPS_PIRACY_STREAMS_LOADED,
  CYCLOPS_PIRACY_STREAMS_CLEAR,
  CYCLOPS_AUTHED_DOMAINS_LOADED,
  CYCLOPS_AUTHED_DOMAINS_CLEAR,
} from '../actionTypes';

import * as api from '../apiHelper';
import { initiateRedirectRequest } from '../redirectActions';
import {
  setFetchLoaderLoading,
  setFetchLoaderReset,
  setSecondaryFetchLoaderLoading,
  setSecondaryFetchLoaderReset,
  setSubmissionLoaderLoading,
  setSubmissionLoaderReset,
} from '../fetchLoaderActions';
import { arriveNotice } from '../noticeActions';
import { setFaviconLoading, setFaviconDefault } from '../faviconActions';

// Reducer corresponding actions
function cyclopsCompanyOrganizationsClear() {
  return { type: CYCLOPS_ORGANIZATIONS_DATA_CLEAR };
}

function cyclopsCompanyOrganizationsLoaded(companyOrganizations) {
  return {
    type: CYCLOPS_ORGANIZATIONS_DATA_LOADED,
    payload: { companyOrganizations },
  };
}

function cyclopsActiveOrganizationClear() {
  return { type: CYCLOPS_ACTIVE_ORGANIZATION_CLEAR };
}

function cyclopsActiveOrganizationSet(activeOrganization) {
  return {
    type: CYCLOPS_ACTIVE_ORGANIZATION_SET,
    payload: { activeOrganization },
  };
}

function cyclopsLivestreamsDataClear() {
  return { type: CYCLOPS_LIVESTREAMS_DATA_CLEAR };
}

function cyclopsLivestreamsDataLoaded(livestreams) {
  return {
    type: CYCLOPS_LIVESTREAMS_DATA_LOADED,
    payload: { livestreams },
  };
}

function cyclopsLivestreamDetailsClear() {
  return { type: CYCLOPS_LIVESTREAM_DETAILS_CLEAR };
}

function cyclopsLivestreamDetailsLoaded(livestreamDetails) {
  return {
    type: CYCLOPS_LIVESTREAM_DETAILS_LOADED,
    payload: { livestreamDetails },
  };
}

function cyclopsPiracyStreamsClear() {
  return { type: CYCLOPS_PIRACY_STREAMS_CLEAR };
}

function cyclopsPiracyStreamsLoaded(piracyStreams) {
  return {
    type: CYCLOPS_PIRACY_STREAMS_LOADED,
    payload: { piracyStreams },
  };
}

function cyclopsAuthedDomainsClear() {
  return { type: CYCLOPS_AUTHED_DOMAINS_CLEAR };
}

function cyclopsAuthedDomainsLoaded(authedDomains) {
  return {
    type: CYCLOPS_AUTHED_DOMAINS_LOADED,
    payload: { authedDomains },
  };
}

// API
// Organizations
async function getCompanyOrganizations(args, token) {
  const response = await api.nestedParamsGet('cyclops/content_owners', args, token, 'v3');
  return response.json();
}

async function submitNewOrganizationApi(body, token) {
  const response = await api.post('cyclops/content_owners', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function editOrganizationApi(id, body, token) {
  const response = await api.put(`cyclops/content_owners/${id}`, body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function deleteOrganizationApi(orgId, body, token) {
  await api.del(`cyclops/content_owners/${orgId}`, body, token, 'v3', true);
}

async function uploadOrganizationVodApi(orgId, body, token) {
  await api.uploadFile(`cyclops/content_owners/${orgId}/upload_vod`, body, token, 'v3');
}

async function updateOrganizationCDNSettingsApi(orgId, body, token) {
  await api.patch(`cyclops/content_owners/${orgId}/update_cdn_settings`, body, token, 'v3');
}

// Livestreams
async function getCompanyLivestreams(args, token) {
  const response = await api.nestedParamsGet('cyclops/live_streams', args, token, 'v3');
  return response.json();
}

async function getLivestreamDetails(id, args, token) {
  const response = await api.get(`cyclops/live_streams/${id}`, args, token, 'v3');
  return response.json();
}

async function submitNewLivestreamApi(body, token) {
  const response = await api.post('cyclops/live_streams', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function editLivestreamApi(body, token) {
  const response = await api.put('cyclops/live_streams', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function deleteLivestreamApi(body, token) {
  await api.del('cyclops/live_streams', body, token, 'v3', true);
}

async function startOrStopLivestreamApi(body, token) {
  await api.patch(`cyclops/live_streams/${body.Id}/start_or_stop_stream`, body, token, 'v3');
}

// Piracy streams
async function getPiracyStreams(args, token) {
  const response = await api.nestedParamsGet('cyclops/piracy_streams', args, token, 'v3');
  return response.json();
}

async function killPiracyStreamApi(body, token) {
  await api.patch('cyclops/piracy_streams/send_kill_switch', body, token, 'v3', true);
}

// Authed domains
async function getAuthedDomains(args, token) {
  const response = await api.get('cyclops/authed_domains', args, token, 'v3');
  return response.json();
}

async function submitNewAuthedDomainApi(body, token) {
  const response = await api.post('cyclops/authed_domains', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function editAuthedDomainApi(body, token) {
  const response = await api.put('cyclops/authed_domains', body, token, 'v3');

  if (!response.ok) {
    const json = await response.json();
    throw Error(json.error);
  }

  return response.json();
}

async function deleteAuthedDomainApi(body, token) {
  await api.del('cyclops/authed_domains', body, token, 'v3', true);
}

// Helper
// Organizations
async function fetchCompanyOrganizations(dispatch, args, token) {
  try {
    const response = await getCompanyOrganizations(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsCompanyOrganizationsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function submitCompanyOrganization(dispatch, body, token, redirectPath, afterAction) {
  try {
    const { notice } = await submitNewOrganizationApi(body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
    afterAction();
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function editCompanyOrganization(dispatch, id, body, token, redirectPath, afterAction) {
  try {
    const { notice } = await editOrganizationApi(id, body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
    afterAction();
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function uploadCompanyVod(dispatch, orgId, body, token) {
  try {
    await uploadOrganizationVodApi(orgId, body, token);

    dispatch(arriveNotice('Vod uploaded successfully!', 'ok'));
    dispatch(setSubmissionLoaderReset());
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function updateCompanyCDNSettings(dispatch, orgId, body, token) {
  try {
    const { notice } = await updateOrganizationCDNSettingsApi(orgId, body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Livestreams
async function fetchCompanyLivestreams(dispatch, args, token) {
  try {
    const response = await getCompanyLivestreams(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsLivestreamsDataLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function submitCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    const { notice } = await submitNewLivestreamApi(body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function editCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    const { notice } = await editLivestreamApi(body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function deleteCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    const { notice } = await deleteLivestreamApi(body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function startOrStopCompanyLivestream(dispatch, body, token, redirectPath) {
  try {
    const { notice } = await startOrStopLivestreamApi(body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function fetchLivestreamDetails(dispatch, id, args, token) {
  try {
    const response = await getLivestreamDetails(id, args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsLivestreamDetailsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Piracy Streams
async function fetchPiracyStreams(dispatch, args, token) {
  try {
    const response = await getPiracyStreams(args, token);

    dispatch(setFaviconDefault());
    dispatch(setSecondaryFetchLoaderReset());
    dispatch(cyclopsPiracyStreamsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setSecondaryFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

async function killPiracyStream(dispatch, body, token, redirectPath) {
  try {
    const { notice } = await killPiracyStreamApi(body, token);

    dispatch(arriveNotice(notice, 'ok'));
    dispatch(setSubmissionLoaderReset());
    dispatch(initiateRedirectRequest(null, redirectPath, true));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setSubmissionLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Authed Domains
async function fetchAuthedDomains(dispatch, args, token) {
  try {
    const response = await getAuthedDomains(args, token);

    dispatch(setFaviconDefault());
    dispatch(setFetchLoaderReset());
    dispatch(cyclopsAuthedDomainsLoaded(response));
  } catch (e) {
    if (e.name !== 'AbortError') {
      dispatch(setFaviconDefault());
      dispatch(setFetchLoaderReset());
      dispatch(arriveNotice(e.message));
    }
  }
}

// Public components actions
// Organizations
export function clearCyclopsCompanyOrganizationsRequest() {
  return (dispatch) => {
    dispatch(cyclopsCompanyOrganizationsClear());
  };
}

export function initiateCyclopsCompanyOrganizationsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchCompanyOrganizations(dispatch, args, token);
  };
}

export function clearCyclopsActiveOrganizationRequest() {
  return (dispatch) => {
    dispatch(cyclopsActiveOrganizationClear());
  };
}

export function setCyclopsActiveOrganizationRequest(activeOrganization) {
  return (dispatch) => {
    dispatch(cyclopsActiveOrganizationSet(activeOrganization));
  };
}

export function submitNewCyclopsOrganizationRequest(body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await submitCompanyOrganization(dispatch, body, token, redirectPath, afterAction);
  };
}

export function editCyclopsOrganizationRequest(id, body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await editCompanyOrganization(dispatch, id, body, token, redirectPath, afterAction);
  };
}

export function deleteCyclopsOrganizationRequest(orgId, body, redirectPath, afterAction) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await deleteOrganizationApi(orgId, body, token);

      dispatch(initiateRedirectRequest(null, redirectPath, true));
      afterAction();
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function uploadCyclopsOrganizationVodRequest(orgId, body) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await uploadCompanyVod(dispatch, orgId, body, token);
  };
}

export function updateCyclopsOrganizationCDNSettingsRequest(orgId, body) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await updateCompanyCDNSettings(dispatch, orgId, body, token);
  };
}

// Livestreams
export function clearCyclopsLivestreamsRequest() {
  return (dispatch) => {
    dispatch(cyclopsLivestreamsDataClear());
  };
}

export function initiateCyclopsLivestreamsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchCompanyLivestreams(dispatch, args, token);
  };
}

export function submitNewCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await submitCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function editCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await editCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function deleteCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await deleteCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function startOrStopCyclopsLivestreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await startOrStopCompanyLivestream(dispatch, body, token, redirectPath);
  };
}

export function clearCyclopsLivestreamDetailsRequest() {
  return (dispatch) => {
    dispatch(cyclopsLivestreamDetailsClear());
  };
}

export function initiateCyclopsLivestreamDetailsRequest(id, args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchLivestreamDetails(dispatch, id, args, token);
  };
}

// Piracy streams
export function clearCyclopsPiracyStreamsRequest() {
  return (dispatch) => {
    dispatch(cyclopsPiracyStreamsClear());
  };
}

export function initiateCyclopsPiracyStreamsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { secondaryDataLoading },
    } = getState();

    if (!secondaryDataLoading) dispatch(setSecondaryFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchPiracyStreams(dispatch, args, token);
  };
}

export function killCyclopsPiracyStreamRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      fetchLoader: { submissionLoading },
    } = getState();

    if (!submissionLoading) dispatch(setSubmissionLoaderLoading());

    await killPiracyStream(dispatch, body, token, redirectPath);
  };
}

// Authed domains
export function clearCyclopsAuthedDomainsRequest() {
  return (dispatch) => {
    dispatch(cyclopsAuthedDomainsClear());
  };
}

export function initiateCyclopsAuthedDomainsRequest(args) {
  return async (dispatch, getState) => {
    const {
      auth: { token },
      favicon: { faviconStatus },
      fetchLoader: { dataLoading },
    } = getState();

    if (!dataLoading) dispatch(setFetchLoaderLoading());

    if (faviconStatus !== 'loading') {
      dispatch(setFaviconLoading());
    }

    await fetchAuthedDomains(dispatch, args, token);
  };
}

export function submitNewCyclopsAuthedDomainRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      const { notice } = await submitNewAuthedDomainApi(body, token);

      dispatch(arriveNotice(notice, 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function editCyclopsAuthedDomainRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      const { notice } = await editAuthedDomainApi(body, token);

      dispatch(arriveNotice(notice, 'ok'));
      dispatch(initiateRedirectRequest(null, redirectPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}

export function deleteCyclopsAuthedDomainRequest(body, redirectPath) {
  return async (dispatch, getState) => {
    try {
      const { token } = getState().auth;
      await deleteAuthedDomainApi(body, token);

      dispatch(initiateRedirectRequest(null, redirectPath, true));
    } catch (e) {
      dispatch(arriveNotice(e.message));
    }
  };
}
