import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import uniqid from 'uniqid';
import _ from 'lodash';

import { Box, Text } from 'grommet';

import { Seo, AppButton } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { AuthNavWrapper } from '@Components/Navigation';
import { SettingsDemoContentPartial } from '@Components/Partial/Product/Cyclops';
import { paths } from '@Components/configs';
import {
  uploadCyclopsOrganizationVodRequest,
  updateCyclopsOrganizationCDNSettingsRequest,
} from '@Actions';

import { FormInputSelector } from '../../../Partial/Product/Cyclops/CyclopsComponents';
import {
  StyledCyclopsHeading,
  StyledCyclopsContainer,
  StyledHr,
} from '../../../Partial/Product/Cyclops/StyledCyclopsComponents';

const CyclopsSettingsPage = ({
  small,
  demoOnly,
  mixpanel,
  location,
  activeOrganization,
  uploadOrganizationVod,
  updateOrganizationCDNSettings,
  companyOrganizations = null,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyDemoData = null,
}) => {
  const navigate = useNavigate();
  const vodInputRef = React.useRef(null);
  /* eslint-disable camelcase */
  const [formValues, setFormValues] = React.useState({
    inputValues: {
      cdn: 'fastly',
      dictionary_id: '',
      service_id: '',
      api_key: '',
    },
  });

  const currentOrg = _.find(companyOrganizations, { Id: activeOrganization?.uid });

  React.useEffect(() => {
    if (!demoOnly && !activeOrganization) navigate(paths.cyclopsOrganizations);

    if (currentOrg?.cdnConfigurations?.fastly) {
      setFormValues({
        inputValues: {
          cdn: 'fastly',
          dictionary_id: currentOrg?.cdnConfigurations?.fastly.dictionaryId,
          service_id: currentOrg?.cdnConfigurations?.fastly.serviceId,
          api_key: currentOrg?.cdnConfigurations?.fastly.apiKey,
        },
      });
    }
  }, [activeOrganization]);

  const handleFormValues = (key, value) => {
    setFormValues((prevState) => ({
      ...prevState,
      inputValues: {
        ...prevState.inputValues,
        [key]: value,
      },
    }));
  };

  const handleResetForm = () => {
    setFormValues({
      inputValues: {
        cdn: 'fastly',
        dictionary_id: '',
        service_id: '',
        api_key: '',
      },
    });
  };

  const submitFormValues = () => {
    updateOrganizationCDNSettings(activeOrganization.uid, formValues.inputValues);

    handleResetForm();
  };

  const {
    darkModeColors: {
      primaryDarkBg, containerBg, primaryText, secondaryText,
    },
    buttonHighlight,
  } = authPagesConfig;

  const renderPageContent = () => {
    if (demoOnly) {
      return (
        <SettingsDemoContentPartial
          small={small}
          authPagesConfig={authPagesConfig}
          companyDemoData={companyDemoData}
        />
      );
    }

    const currentVod = currentOrg?.vodFilename ? currentOrg.vodFilename : 'No vod uploaded';

    return (
      <Box flex background={primaryDarkBg} direction="column" gap="1.5rem">
        <StyledCyclopsContainer
          background={containerBg}
          direction="column"
          width={small ? '75%' : '45%'}
        >
          <Box direction="column" gap="0.5rem">
            <Text size="1.1rem" weight={600} color={primaryText}>
              Upload Organization Vod
            </Text>
            <StyledHr color="#D0D0D0" />
          </Box>
          <input
            hidden
            key={uniqid()}
            type="file"
            accept="video/*"
            onChange={(e) => uploadOrganizationVod(
              activeOrganization.uid, e.currentTarget.files[0],
            )}
            ref={vodInputRef}
          />
          <Box direction="column" gap="0.5rem" pad={{ top: '0.5rem', bottom: '1rem' }}>
            <Text size="1rem" weight={500} color={secondaryText}>
              Current vod filename:
            </Text>
            <Text size="1rem" color={primaryText}>
              {currentVod}
            </Text>
          </Box>
          <AppButton
            overrideHover
            level="dynamicLarge"
            color="white"
            width="7.5rem"
            bgColor={buttonHighlight}
            onClick={() => vodInputRef.current.click()}
            fontWeight={600}
            label="Upload file"
          />
        </StyledCyclopsContainer>
        <StyledCyclopsContainer
          background={containerBg}
          direction="column"
          gap="0.5rem"
          width={small ? '90%' : '75%'}
        >
          <Text size="1.1rem" weight={600} color={primaryText}>
            Update CDN Settings
          </Text>
          <StyledHr color="#D0D0D0" />
          <Box pad={{ bottom: '0.5rem' }}>
            <Text size="1.1rem" weight={500} color={secondaryText}>
              Fastly
            </Text>
          </Box>
          <FormInputSelector
            small={small}
            label="Dictionary ID"
            renderKey="text"
            inputKey="dictionary_id"
            inputType="text"
            value={formValues.inputValues.dictionary_id}
            placeholder="Fastly dictionary ID"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="Service ID"
            renderKey="text"
            inputKey="service_id"
            inputType="text"
            value={formValues.inputValues.service_id}
            placeholder="Service ID"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <FormInputSelector
            small={small}
            label="API Key"
            renderKey="text"
            inputKey="api_key"
            inputType="text"
            value={formValues.inputValues.api_key}
            placeholder="Fastly API key"
            handleFormValues={handleFormValues}
            authPagesConfig={authPagesConfig}
          />
          <AppButton
            overrideHover
            level="dynamicLarge"
            color="white"
            width="7.5rem"
            bgColor={buttonHighlight}
            onClick={() => submitFormValues()}
            fontWeight={600}
            label="Save settings"
          />
        </StyledCyclopsContainer>
      </Box>
    );
  };
  /* eslint-enable camelcase */

  const pageTitle = demoOnly ? 'Cyclops Settings' : 'Organization CDN Settings';

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      <Box
        flex
        background={primaryDarkBg}
        pad={small ? '2rem' : '3rem'}
        direction="column"
        gap="1.5rem"
      >
        <StyledCyclopsHeading level={2} color={primaryText}>
          {pageTitle}
        </StyledCyclopsHeading>
        {renderPageContent()}
      </Box>
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    companyOrganizations: state.cyclops.companyOrganizations,
    activeOrganization: state.cyclops.activeCompanyOrganization,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      uploadOrganizationVod: uploadCyclopsOrganizationVodRequest,
      updateOrganizationCDNSettings:
        updateCyclopsOrganizationCDNSettingsRequest,
    },
    dispatch,
  );
}

CyclopsSettingsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  demoOnly: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
  }),
  companyOrganizations: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    companyId: PropTypes.number.isRequired,
    piracyDomains: PropTypes.arrayOf(PropTypes.string),
    encoderOutboundProtocol: PropTypes.string,
    encoderOutboundVideoCodec: PropTypes.string,
    encoderOutboundAudioCodec: PropTypes.string,
    cyclopsStartsPostAbr: PropTypes.bool,
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    vodFilename: PropTypes.string,
    cdnConfigurations: PropTypes.shape({
      fastly: PropTypes.shape({
        dictionaryId: PropTypes.string,
        serviceId: PropTypes.string,
        apiKey: PropTypes.string,
      }),
    }),
  })),
  activeOrganization: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyDemoData: PropTypes.objectOf(PropTypes.any),
  uploadOrganizationVod: PropTypes.func.isRequired,
  updateOrganizationCDNSettings: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withProductAuth(CyclopsSettingsPage));
