import {
  FETCH_LOADER_LOADING,
  FETCH_LOADER_FINISHED,
  SECONDARY_FETCH_LOADER_LOADING,
  SECONDARY_FETCH_LOADER_FINISHED,
  SUBMISSION_LOADER_LOADING,
  SUBMISSION_LOADER_FINISHED,
} from '../actions/actionTypes';

const initialState = {
  dataLoading: false,
  secondaryDataLoading: false,
  submissionLoading: false,
};

const fetchLoader = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOADER_LOADING:
      return { ...state, dataLoading: true };

    case FETCH_LOADER_FINISHED:
      return { ...state, dataLoading: false };

    case SECONDARY_FETCH_LOADER_LOADING:
      return { ...state, secondaryDataLoading: true };

    case SECONDARY_FETCH_LOADER_FINISHED:
      return { ...state, secondaryDataLoading: false };

    case SUBMISSION_LOADER_LOADING:
      return { ...state, submissionLoading: true };

    case SUBMISSION_LOADER_FINISHED:
      return { ...state, submissionLoading: false };

    default:
      return state;
  }
};

export default fetchLoader;
