import styled from 'styled-components';

import {
  Box, Heading, Button,
} from 'grommet';
import { ReactSVG } from 'react-svg';
import ReactTooltip from 'react-tooltip';

const StyledCyclopsContainer = styled(Box)`
  border-radius: 10px;
  padding: ${({ padding }) => padding ?? '1rem'};
`;

const StyledCyclopsHeading = styled(Heading)`
  font-weight: 600;
  line-height: 30px;
  margin-block-start: ${({ noMargin }) => (noMargin ? '0' : '1.5rem')};
  margin-block-end: 0;
  font-size: 1.5rem;
`;

const StyledHr = styled.hr`
  color: ${({ color }) => color};
  width: 100%;
  margin-inline-end: 0;
  margin-inline-start: 0;
`;

const StyledSelectionContainer = styled(Button)`
  border-radius: 10px;
  position: relative;

  .checkMark {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  img { border: 1px solid transparent; }
  
  &:hover img {
    border: ${({ highlightColor }) => `1px solid ${highlightColor}`};
  }
`;

const StyledSVG = styled(ReactSVG)`
  svg {
    height: ${({ height }) => height};
    width:${({ width }) => width};
    fill: ${({ $fillColor }) => $fillColor};

    path {
      fill: ${({ $fillColor }) => $fillColor};
    }
  }
`;

const StyledTooltip = styled(ReactTooltip)`
  font-family: inherit;
  border-radius: 6px;
`;

const StyledEventButton = styled(Button)`
  display: flex;
  flex-basis: 30%;

  .imageContainer {
    position: relative;
  }

  .liveBadge {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
  }
`;

const StyledIconButton = styled(Button)`
  border-radius: 5px;
  padding: 0.25rem 0.6rem;
  background: ${({ bgColor }) => bgColor ?? 'white'};
  font-weight: 600;
  margin: 0.25rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledHoverButton = styled(Button)`
  border-radius: 5px;
  padding: 0.25rem 0.6rem;

  &:hover {
    background-color: ${({ hoverColor }) => hoverColor};
  }
`;

export {
  StyledCyclopsContainer,
  StyledCyclopsHeading,
  StyledHr,
  StyledSelectionContainer,
  StyledSVG,
  StyledTooltip,
  StyledEventButton,
  StyledIconButton,
  StyledHoverButton,
};
