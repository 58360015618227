import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text,
} from 'grommet';

import { AppButton } from '@Components/Control';
import { paths } from '@Components/configs';

import { ActionButton } from './CyclopsComponents';
import { renderTableHeader, NoDataPlaceholder } from '../SharedComponents';
import { StyledFlexibleDrop } from '../StyledSharedComponents';


const StreamDataCell = ({
  small,
  textColor,
  headerColor,
  buttonHighlight,
  data,
  uid,
  size,
  statusDisplayMap,
  headerMap,
  handleEdit,
  handleDelete,
  handleStartOrStop,
  actions,
}) => {
  const rowPad = { horizontal: small ? '0.25rem' : '0.5rem', vertical: small ? '0.25rem' : '1rem' };
  const currentHeader = headerMap.find((header) => header.uid === uid);

  const renderActionButtons = (actionUid, onClose) => {
    switch (actionUid) {
      case 'edit':
        return (
          <ActionButton
            key={actionUid}
            small={small}
            label="Edit"
            iconPath="images/edit-pencil-icon.svg"
            textColor={textColor}
            hoverColor={buttonHighlight}
            actionHandler={() => { handleEdit(data.Id); onClose(); }}
          />
        );

      case 'delete':
        return (
          <ActionButton
            key={actionUid}
            small={small}
            label="Delete"
            iconPath="images/delete-content-icon.svg"
            textColor={textColor}
            hoverColor={buttonHighlight}
            actionHandler={() => { handleDelete(data.Id); onClose(); }}
          />
        );

      case 'details':
        return (
          <ActionButton
            key={actionUid}
            small={small}
            label="See details"
            iconPath="images/whitelist-icon.svg"
            textColor={textColor}
            hoverColor={buttonHighlight}
            actionHandler={() => onClose()}
            href={paths.cyclopsEventDetails.replace(':id', data.Id)}
          />
        );

      default:
        return null;
    }
  };

  const withStartStopControls = actions?.length > 0 && (data.status === 'ready' || data.status === 'live');
  const actionBoolean = data.status === 'ready';
  const actionText = actionBoolean ? 'Start stream' : 'Stop stream';

  const handleValue = (key, value) => {
    switch (key) {
      case 'actions':
        return (
          <Box direction={small ? 'column' : 'row'} gap={small ? '1rem' : '2rem'} align="start">
            <StyledFlexibleDrop
              formIcons
              height="1.75rem"
              width="8rem"
              bgColor="#454A59"
              borderColor="rgba(0,0,0,0)"
              withCloseDropContent={(onClose) => (
                <Box direction="column" round="0.35rem" pad="12px" gap="0.5rem" background="#454A59">
                  {withStartStopControls && (
                    <ActionButton
                      key={actionBoolean ? 'start' : 'stop'}
                      small={small}
                      label={actionText}
                      iconPath="images/power-symbol-icon.svg"
                      textColor={textColor}
                      hoverColor={buttonHighlight}
                      actionHandler={() => {
                        handleStartOrStop(data.Id, actionBoolean);
                        onClose();
                      }}
                    />
                  )}
                  {actions.map((actionUid) => renderActionButtons(actionUid, onClose))}
                </Box>
              )}
            >
              {(Icon) => (
                <AppButton
                  fill
                  dropDown
                  overrideHover
                  textSize="1rem"
                  color={textColor}
                  bgColor="#454A59"
                >
                  <Box>
                    <Box direction="row" gap="1rem" width="100%" justify="start">
                      <Text color={textColor} size="1rem">
                        Stream controls
                      </Text>
                      <Box pad={{ top: '0.15rem' }}>
                        <Icon color={textColor} size="1rem" />
                      </Box>
                    </Box>
                  </Box>
                </AppButton>
              )}
            </StyledFlexibleDrop>
          </Box>
        );

      case 'startDateTime':
      case 'endDateTime':
        return (
          <Text size="1rem" color={textColor}>{new Date(value).toLocaleString()}</Text>
        );

      case 'status':
        return (
          <Text size="1rem" color={textColor}>{statusDisplayMap[value]}</Text>
        );

      default:
        return <Text size="1rem" color={textColor}>{value}</Text>;
    }
  };

  if (small) {
    return (
      <Box key={uniqid()} direction="row" width={small ? '100%' : size} pad={rowPad} justify="between">
        {renderTableHeader(currentHeader.display, small, headerColor)}
        {handleValue(uid, data[uid])}
      </Box>
    );
  }

  return (
    <Box key={uniqid()} width={small ? '100%' : size} pad={rowPad} justify="center">
      {handleValue(uid, data[uid])}
    </Box>
  );
};

StreamDataCell.propTypes = {
  small: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    Id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  headerMap: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  }).isRequired).isRequired,
  statusDisplayMap: PropTypes.object.isRequired,
  textColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  headerColor: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleStartOrStop: PropTypes.func.isRequired,
  actions: PropTypes.arrayOf(PropTypes.string.isRequired),
};

const LivestreamsTable = ({
  small,
  loading,
  tableTitle,
  textColor,
  highlightColor,
  buttonHighlight,
  dividerColor,
  containerBg,
  data = null,
  headerMap,
  statusDisplayMap,
  handleEdit,
  handleDelete,
  handleStartOrStop,
  noDataLabel,
}) => {
  const renderTableContents = () => {
    if (loading || data?.length === 0) {
      return (
        <NoDataPlaceholder
          noShadow
          noRound
          darkmode
          backgroundColor={containerBg}
          buttonHighlight={buttonHighlight}
          loading={loading}
          label={noDataLabel}
          textColor={textColor}
          loaderColor={buttonHighlight}
        />
      );
    }

    return (
      <Box>
        {data?.length > 0 && data.map((dt) => (
          <Box
            direction="column"
            key={uniqid()}
            border={{
              color: dividerColor, size: '1px', style: 'solid', side: 'bottom',
            }}
            pad={{ vertical: small ? '1rem' : '0' }}
          >
            <Box fill="horizontal" wrap={false} direction={small ? 'column' : 'row'} gap={small ? '0.5rem' : '0'}>
              {headerMap.map(({ uid, size, actions }) => (
                <StreamDataCell
                  key={uniqid()}
                  small={small}
                  textColor={textColor}
                  headerColor={highlightColor}
                  data={dt}
                  uid={uid}
                  size={size}
                  statusDisplayMap={statusDisplayMap}
                  headerMap={headerMap}
                  handleEdit={handleEdit}
                  handleStartOrStop={handleStartOrStop}
                  handleDelete={handleDelete}
                  actions={actions}
                  buttonHighlight={buttonHighlight}
                />
              ))}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box direction="column" background={containerBg} pad="2rem" round="10px">
      <Box
        fill="horizontal"
        border={{
          color: dividerColor, size: '2px', style: 'solid', side: 'bottom',
        }}
        pad={{ bottom: '1rem' }}
      >
        <Text size="1.25rem" weight={600} color={textColor} textAlign="start">
          {tableTitle}
        </Text>
      </Box>
      {!small && !loading && data?.length > 0 && (
        <Box
          fill="horizontal"
          wrap={false}
          direction="row"
          border={{
            color: dividerColor, size: '1px', style: 'solid', side: 'bottom',
          }}
        >
          {headerMap.map(({ uid, display, size }) => (
            <Box key={uid} width={size} pad={{ horizontal: '0.5rem', vertical: '1rem' }}>
              {renderTableHeader(display, small, highlightColor)}
            </Box>
          ))}
        </Box>
      )}
      {renderTableContents()}
    </Box>
  );
};

LivestreamsTable.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  tableTitle: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  buttonHighlight: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  dividerColor: PropTypes.string.isRequired,
  containerBg: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    Id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    endDateTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired),
  headerMap: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired).isRequired,
  statusDisplayMap: PropTypes.object.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleStartOrStop: PropTypes.func.isRequired,
  noDataLabel: PropTypes.string.isRequired,
};

export default LivestreamsTable;
