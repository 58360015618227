import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import { Box } from 'grommet';

import { Seo } from '@Components/Control';
import { withProductAuth } from '@Components/Layout';
import { CurrentDateContext } from '@Components/Context';
import { AuthNavWrapper } from '@Components/Navigation';
import { paths } from '@Components/configs';
import {
  EventDetailsDemoContentPartial, EventDetailsPartial, PiracyStreamsPartial,
} from '@Components/Partial/Product/Cyclops';

import {
  initiateCyclopsLivestreamDetailsRequest,
  clearCyclopsLivestreamDetailsRequest,
  startOrStopCyclopsLivestreamRequest,
  initiateCyclopsCompanyOrganizationsRequest,
  setCyclopsActiveOrganizationRequest,
  initiateCyclopsPiracyStreamsRequest,
  clearCyclopsPiracyStreamsRequest,
  killCyclopsPiracyStreamRequest,
} from '@Actions';


const CyclopsEventDetailsPage = ({
  small,
  demoOnly,
  mixpanel,
  location,
  loading,
  submitLoading,
  fetchCompanyOrganizations,
  setActiveOrganization,
  fetchLivestreamDetails,
  clearLivestreamDetails,
  startOrStopLivestream,
  fetchPiracyStreams,
  clearPiracyStreams,
  killPiracyStream,
  piracyStreamsLoading,
  livestreamDetails = null,
  piracyStreamData = null,
  companyOrganizations = null,
  activeOrganization = null,
  authPagesConfig = null,
  reportsConfig = null,
  cyclopsConfig = null,
  companyDemoData = null,
}) => {
  const { id: eventUid } = useParams();
  const { today } = React.useContext(CurrentDateContext);
  const formattedDate = today.toLocaleDateString('en-US', {
    month: 'short', day: '2-digit', year: 'numeric',
  });

  React.useEffect(() => {
    if (!demoOnly) {
      fetchLivestreamDetails(eventUid);
      /* eslint-disable camelcase */
      fetchPiracyStreams({ live_stream_id: eventUid });
      /* eslint-enable camelcase */
    }

    if (!demoOnly && !companyOrganizations) {
      fetchCompanyOrganizations();
    }

    return () => {
      if (!demoOnly) {
        clearLivestreamDetails();
        clearPiracyStreams();
      }
    };
  }, []);

  React.useEffect(() => {
    if (companyOrganizations?.length > 0 && !activeOrganization && livestreamDetails) {
      const currentOrg = _.find(companyOrganizations, { Id: livestreamDetails?.contentOwnerId });
      setActiveOrganization({ uid: currentOrg.Id, name: currentOrg.name });
    }
  }, [companyOrganizations, livestreamDetails]);

  const handleStartOrStopLivestream = (streamId, val) => {
    startOrStopLivestream({ Id: streamId, start: val }, paths.cyclopsEventDetails.replace(':id', eventUid));
  };

  const {
    darkModeColors: {
      primaryDarkBg,
    },
  } = authPagesConfig;

  const renderPageContent = () => {
    if (demoOnly) {
      return (
        <EventDetailsDemoContentPartial
          small={small}
          formattedDate={formattedDate}
          eventUid={eventUid}
          authPagesConfig={authPagesConfig}
          companyDemoData={companyDemoData}
        />
      );
    }

    return (
      <Box flex background={primaryDarkBg} pad={small ? '2rem' : '3rem'} direction="column" gap="1.5rem">
        <EventDetailsPartial
          small={small}
          loading={loading || submitLoading}
          formattedDate={formattedDate}
          eventUid={eventUid}
          authPagesConfig={authPagesConfig}
          eventData={livestreamDetails}
          activeOrganization={activeOrganization}
          startOrStopLivestream={handleStartOrStopLivestream}
          companyOrganizations={companyOrganizations}
        />
        <PiracyStreamsPartial
          small={small}
          loading={piracyStreamsLoading || submitLoading}
          authPagesConfig={authPagesConfig}
          piracyStreamData={piracyStreamData}
          killPiracyStream={killPiracyStream}
        />
      </Box>
    );
  };

  return (
    <AuthNavWrapper
      hideBanner
      darkMode
      small={small}
      mixpanel={mixpanel}
      location={location}
      authPagesConfig={authPagesConfig}
      reportsConfig={reportsConfig}
      cyclopsConfig={cyclopsConfig}
    >
      <Seo />
      {renderPageContent()}
    </AuthNavWrapper>
  );
};

function mapStateToProps(state) {
  return {
    activeOrganization: state.cyclops.activeCompanyOrganization,
    livestreamDetails: state.cyclops.livestreamDetails,
    companyOrganizations: state.cyclops.companyOrganizations,
    piracyStreamData: state.cyclops.piracyStreams,
    loading: state.fetchLoader.dataLoading,
    piracyStreamsLoading: state.fetchLoader.secondaryDataLoading,
    submitLoading: state.fetchLoader.submissionLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchLivestreamDetails: initiateCyclopsLivestreamDetailsRequest,
    clearLivestreamDetails: clearCyclopsLivestreamDetailsRequest,
    startOrStopLivestream: startOrStopCyclopsLivestreamRequest,
    fetchCompanyOrganizations: initiateCyclopsCompanyOrganizationsRequest,
    setActiveOrganization: setCyclopsActiveOrganizationRequest,
    fetchPiracyStreams: initiateCyclopsPiracyStreamsRequest,
    clearPiracyStreams: clearCyclopsPiracyStreamsRequest,
    killPiracyStream: killCyclopsPiracyStreamRequest,
  }, dispatch);
}

CyclopsEventDetailsPage.propTypes = {
  small: PropTypes.bool.isRequired,
  demoOnly: PropTypes.bool.isRequired,
  mixpanel: PropTypes.shape({
    track: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
  }).isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
    }).isRequired,
    cyclopsStatusDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired).isRequired,
  }),
  reportsConfig: PropTypes.arrayOf(PropTypes.any),
  cyclopsConfig: PropTypes.arrayOf(PropTypes.any),
  companyDemoData: PropTypes.objectOf(PropTypes.any),
  loading: PropTypes.bool.isRequired,
  submitLoading: PropTypes.bool.isRequired,
  piracyStreamsLoading: PropTypes.bool.isRequired,
  livestreamDetails: PropTypes.shape({
    Id: PropTypes.string.isRequired,
    maxVideoHeight: PropTypes.string,
    maxVideoWidth: PropTypes.string,
    contentOwnerId: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    encoderOutboundProtocol: PropTypes.string.isRequired,
    urlOrCidrs: PropTypes.string,
    currentOriginManifestPath: PropTypes.string.isRequired,
    currentHostVideoBaseFolder: PropTypes.string.isRequired,
    clientMediaId: PropTypes.string,
    protocolConfig: PropTypes.object,
    endDateTime: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDateTime: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    updatedAt: PropTypes.string.isRequired,
  }),
  piracyStreamData: PropTypes.arrayOf(PropTypes.shape({
    piracyUrl: PropTypes.string.isRequired,
    liveStreamId: PropTypes.string.isRequired,
    pirateId: PropTypes.string.isRequired,
    linkToS3CapturedFootage: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    CDN: PropTypes.string.isRequired,
    metrics: PropTypes.shape({
      viewers: PropTypes.string,
      timeToKill: PropTypes.number,
      foundAtTime: PropTypes.number,
      shutDownAt: PropTypes.string.isRequired,
      decodingTime: PropTypes.string.isRequired,
    }).isRequired,
    watermarkId: PropTypes.string,
  })),
  companyOrganizations: PropTypes.arrayOf(PropTypes.any),
  activeOrganization: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  fetchLivestreamDetails: PropTypes.func.isRequired,
  clearLivestreamDetails: PropTypes.func.isRequired,
  startOrStopLivestream: PropTypes.func.isRequired,
  fetchPiracyStreams: PropTypes.func.isRequired,
  clearPiracyStreams: PropTypes.func.isRequired,
  killPiracyStream: PropTypes.func.isRequired,
  fetchCompanyOrganizations: PropTypes.func.isRequired,
  setActiveOrganization: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withProductAuth(CyclopsEventDetailsPage),
);
