import React from 'react';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';

import {
  Box, Text, Button, Anchor,
} from 'grommet';

import { localAsset } from '@Helpers/asset';
import { TruncatedText } from './CyclopsComponents';
import { NoDataPlaceholder, renderTableHeader } from '../SharedComponents';
import { StyledSVG, StyledTooltip } from './StyledCyclopsComponents';


const DataRow = ({
  small, value, textColor, secondaryTextColor, highlightColor, isUrl = false, label = null,
}) => {
  const handleValue = (val) => {
    if (isUrl) {
      return (
        <Anchor href={val} target="_blank">
          <TruncatedText
            small={small}
            value={val}
            truncateLength={small ? '40' : '70'}
            color={textColor}
            highlightColor={highlightColor}
            noDataText="N/A"
          />
        </Anchor>
      );
    }

    return (
      <TruncatedText
        small={small}
        value={val}
        truncateLength={small ? '40' : '70'}
        color={textColor}
        highlightColor={highlightColor}
        noDataText="N/A"
      />
    );
  };

  return (
    <Box direction="row" gap="0.5rem">
      {small && label && (
        <Text size="1rem" color={secondaryTextColor} weight={600}>
          {label}
        </Text>
      )}
      {handleValue(value)}
    </Box>
  );
};

DataRow.propTypes = {
  small: PropTypes.bool.isRequired,
  isUrl: PropTypes.bool,
  value: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  secondaryTextColor: PropTypes.string.isRequired,
  highlightColor: PropTypes.string.isRequired,
  label: PropTypes.string,
};

const PiracyStreamsPartial = ({
  small,
  loading,
  killPiracyStream,
  authPagesConfig,
  piracyStreamData = null,
}) => {
  const {
    darkModeColors: {
      primaryDarkBg,
      containerBg,
      primaryText,
      menuButtonHighlight,
      secondaryText,
    },
    cyclopsStatusDisplayMap,
  } = authPagesConfig;

  const headerMap = [
    { uid: 'piracyUrl', display: 'Infringing URL', size: '32%' },
    {
      uid: 'linkToS3CapturedFootage',
      display: 'Captured Footage',
      size: '33%',
    },
    { uid: 'status', display: 'Status', size: '20%' },
    { uid: 'actions', display: 'Actions', size: '15%' },
  ];

  const rowPad = { horizontal: '0.5rem', vertical: '1rem' };

  /* eslint-disable camelcase */
  const renderActionButton = (imagePath, color, piracyStreamId, isVodSub) => (
    <Button
      plain
      key={uniqid()}
      onClick={() => (
        killPiracyStream({ id: piracyStreamId, is_vod_sub: isVodSub })
      )}
      data-for={`${piracyStreamId}-tooltip`}
      data-tip={isVodSub ? 'Replace Content with Vod' : 'Kill Stream'}
    >
      <Box
        round="5px"
        background={color}
        align="center"
        justify="center"
        width={small ? '2.5rem' : '2rem'}
        height={small ? '2.5rem' : '2rem'}
        pad={{ top: '0.25rem' }}
      >
        <StyledSVG
          src={localAsset(imagePath)}
          height={small ? '1.25rem' : '1.1rem'}
          width={small ? '1.25rem' : '1.1rem'}
          $fillColor="white"
        />
      </Box>
      <StyledTooltip
        id={`${piracyStreamId}-tooltip`}
        arrowColor={menuButtonHighlight}
        backgroundColor={menuButtonHighlight}
        place="bottom"
        effect="solid"
        delayShow={300}
      />
    </Button>
  );
  /* eslint-enable camelcase */

  const renderActionRow = (uid, showControls = false) => {
    if (!showControls) {
      return (
        <Box width={small ? '100%' : '15%'} align="start" justify="center" pad={{ left: '0.5rem' }}>
          <Text size="1rem" color={primaryText}>
            {small ? '' : 'No Actions'}
          </Text>
        </Box>
      );
    }

    return (
      <Box
        width={small ? '100%' : '15%'}
        direction="row"
        align="center"
        justify={small ? 'end' : 'start'}
        gap="1rem"
        pad={{ left: '0.5rem' }}
      >
        {renderActionButton('images/power-symbol-icon.svg', '#EC2C2C', uid, false)}
        {renderActionButton('images/replace-content-icon.svg', '#14191F', uid, true)}
      </Box>
    );
  };

  const renderContent = () => {
    if (loading || !piracyStreamData || piracyStreamData?.length === 0) {
      return (
        <NoDataPlaceholder
          noShadow
          darkmode
          backgroundColor={containerBg}
          buttonHighlight={menuButtonHighlight}
          loading={loading}
          label="Searching for live piracy streams..."
          textColor={primaryText}
          loaderColor={menuButtonHighlight}
        />
      );
    }

    return (
      <Box direction="column" background={containerBg} pad="1rem" round="10px">
        <Box
          fill="horizontal"
          border={{
            color: '#394658',
            size: '2px',
            style: 'solid',
            side: 'bottom',
          }}
          pad={{ bottom: '0.65rem' }}
        >
          <Text
            size="1.25rem"
            weight={600}
            color={primaryText}
            textAlign="start"
          >
            Piracy Streams
          </Text>
        </Box>
        <Box direction="column">
          {!small && (
            <Box
              fill="horizontal"
              wrap={false}
              direction="row"
              border={{
                color: '#E5E9EB',
                size: '1px',
                style: 'solid',
                side: 'bottom',
              }}
            >
              {headerMap.map(({ uid, display, size }) => (
                <Box
                  key={uid}
                  width={size}
                  pad={{ horizontal: '0.5rem', vertical: '1rem' }}
                >
                  {renderTableHeader(display, small, secondaryText)}
                </Box>
              ))}
            </Box>
          )}
          {piracyStreamData.map(({
            piracyUrl, Id, linkToS3CapturedFootage, status,
          }) => (
            <Box
              direction="column"
              key={uniqid()}
              border={{
                color: '#E5E9EB',
                size: '1px',
                style: 'solid',
                side: 'bottom',
              }}
              pad={{ vertical: small ? '1rem' : '0' }}
            >
              <Box
                fill="horizontal"
                wrap={false}
                direction={small ? 'column' : 'row'}
                gap={small ? '0.5rem' : '0'}
              >
                <Box
                  width={small ? '100%' : '32%'}
                  pad={small ? '0' : rowPad}
                  justify={small ? 'start' : 'center'}
                >
                  <DataRow
                    isUrl
                    small={small}
                    value={piracyUrl}
                    textColor={primaryText}
                    secondaryTextColor={secondaryText}
                    highlightColor={menuButtonHighlight}
                    label="Infringing URL"
                  />
                </Box>
                <Box
                  width={small ? '100%' : '33%'}
                  pad={small ? '0' : rowPad}
                  justify={small ? 'start' : 'center'}
                >
                  <DataRow
                    isUrl
                    small={small}
                    value={linkToS3CapturedFootage}
                    textColor={primaryText}
                    secondaryTextColor={secondaryText}
                    highlightColor={menuButtonHighlight}
                    label="Captured Footage"
                  />
                </Box>
                <Box
                  width={small ? '100%' : '20%'}
                  pad={small ? '0' : rowPad}
                  justify={small ? 'start' : 'center'}
                >
                  <DataRow
                    small={small}
                    value={cyclopsStatusDisplayMap[status]}
                    textColor={primaryText}
                    secondaryTextColor={secondaryText}
                    highlightColor={menuButtonHighlight}
                    label="Status"
                  />
                </Box>
                {renderActionRow(Id, status === 'decoded')}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      flex
      background={primaryDarkBg}
      direction="column"
      gap="1rem"
      pad={{ top: small ? '0' : '1rem' }}
    >
      {renderContent()}
    </Box>
  );
};

PiracyStreamsPartial.propTypes = {
  small: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  authPagesConfig: PropTypes.shape({
    pageBg: PropTypes.string.isRequired,
    altComponentBg: PropTypes.string.isRequired,
    navBorder: PropTypes.string.isRequired,
    primaryText: PropTypes.string.isRequired,
    hintText: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    focusHighlight: PropTypes.string.isRequired,
    hoverColor: PropTypes.string.isRequired,
    incrementText: PropTypes.string.isRequired,
    decrementText: PropTypes.string.isRequired,
    buttonHighlight: PropTypes.string.isRequired,
    iconHighlightColor: PropTypes.string.isRequired,
    constructionImage: PropTypes.string.isRequired,
    darkModeColors: PropTypes.shape({
      containerBg: PropTypes.string.isRequired,
      primaryDarkBg: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
      secondaryText: PropTypes.string.isRequired,
      menuButtonHighlight: PropTypes.string.isRequired,
    }).isRequired,
    cyclopsStatusDisplayMap: PropTypes.objectOf(PropTypes.any.isRequired)
      .isRequired,
  }).isRequired,
  piracyStreamData: PropTypes.arrayOf(
    PropTypes.shape({
      piracyUrl: PropTypes.string.isRequired,
      liveStreamId: PropTypes.string.isRequired,
      Id: PropTypes.string.isRequired,
      pirateId: PropTypes.string.isRequired,
      linkToS3CapturedFootage: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      CDN: PropTypes.string.isRequired,
      metrics: PropTypes.shape({
        viewers: PropTypes.string,
        timeToKill: PropTypes.number,
        foundAtTime: PropTypes.number,
        shutDownAt: PropTypes.string.isRequired,
        decodingTime: PropTypes.string.isRequired,
      }).isRequired,
      watermarkId: PropTypes.string,
    }),
  ),
  killPiracyStream: PropTypes.func.isRequired,
};

export default PiracyStreamsPartial;
