/* eslint-disable global-require */
module.exports = {
  // PRODUCT
  // authActions
  authLogInRequest: require('./product/authActions').logInRequest,
  authLogOutRequest: require('./product/authActions').logOutRequest,
  authRefreshRequest: require('./product/authActions').refreshRequest,
  authUploadCompanyLogoRequest: require('./product/authActions').uploadCompanyLogoRequest,
  sendPasswordResetInstructionsRequest: require('./product/authActions').sendPasswordResetInstructionsRequest,
  updatePasswordRequest: require('./product/authActions').updatePasswordRequest,
  // newActions
  initiateNewFormConfigRequest: require('./product/newActions').initiateNewFormConfigRequest,
  submitNewSearchRequest: require('./product/newActions').submitNewSearchRequest,
  submitSearchFeatureRequest: require('./product/newActions').submitSearchFeatureRequest,
  initiateProductUsageRequest: require('./product/newActions').initiateProductUsageRequest,
  clearProductUsageRequest: require('./product/newActions').clearProductUsageRequest,
  // listActions
  initiateProductListSearchesRequest: require('./product/listActions').initiateProductListSearchesRequest,
  clearProductListSearchesRequest: require('./product/listActions').clearProductListSearchesRequest,
  initiateProductListSearchesAddedRequest: require('./product/listActions').initiateProductListSearchesAddedRequest,
  clearProductListSearchesAddedRequest: require('./product/listActions').clearProductListSearchesAddedRequest,
  initiateProductListSearchesMetaRequest: require('./product/listActions').initiateProductListSearchesMetaRequest,
  clearProductListSearchesMetaRequest: require('./product/listActions').clearProductListSearchesMetaRequest,
  initiateProductListSearchesStatsRequest: require('./product/listActions').initiateProductListSearchesStatsRequest,
  clearProductListSearchesStatsRequest: require('./product/listActions').clearProductListSearchesStatsRequest,
  initiateProductListSearchesFlexDataRequest: require('./product/listActions').initiateProductListSearchesFlexDataRequest,
  clearProductListSearchesFlexDataRequest: require('./product/listActions').clearProductListSearchesFlexDataRequest,
  // dashboardActions
  initiateProductDashboardReviewRequest: require('./product/dashboardActions').initiateProductDashboardReviewRequest,
  clearProductDashboardReviewRequest: require('./product/dashboardActions').clearProductDashboardReviewRequest,
  updateProductDashboardReviewSelectionsRequest: require('./product/dashboardActions').updateProductDashboardReviewSelectionsRequest,
  initiateProductDashboardReviewStatsRequest: require('./product/dashboardActions').initiateProductDashboardReviewStatsRequest,
  clearProductDashboardReviewStatsRequest: require('./product/dashboardActions').clearProductDashboardReviewStatsRequest,
  initiateProductDashboardReviewMetaRequest: require('./product/dashboardActions').initiateProductDashboardReviewMetaRequest,
  clearProductDashboardReviewMetaRequest: require('./product/dashboardActions').clearProductDashboardReviewMetaRequest,
  initiateProductDashboardFlexibleReviewDataRequest: require('./product/dashboardActions').initiateProductDashboardFlexibleReviewDataRequest,
  clearProductDashboardFlexibleReviewDataRequest: require('./product/dashboardActions').clearProductDashboardFlexibleReviewDataRequest,
  updateProductDashboardFlexibleReviewSelectionsRequest: require('./product/dashboardActions').updateProductDashboardFlexibleReviewSelectionsRequest,
  initiateProductDashboardSearchResultsRequest: require('./product/dashboardActions').initiateProductDashboardSearchResultsRequest,
  clearProductDashboardSearchResultsRequest: require('./product/dashboardActions').clearProductDashboardSearchResultsRequest,

  // embedActions
  refreshEmbedAccessDataRequest: require('./product/embedActions').refreshEmbedAccessDataRequest,
  clearEmbedAccessDataRequest: require('./product/embedActions').clearEmbedAccessDataRequest,

  // CYCLOPS
  // cyclopsActions - Organizations
  initiateCyclopsCompanyOrganizationsRequest: require('./product/cyclopsActions').initiateCyclopsCompanyOrganizationsRequest,
  clearCyclopsCompanyOrganizationsRequest: require('./product/cyclopsActions').clearCyclopsCompanyOrganizationsRequest,
  submitNewCyclopsOrganizationRequest: require('./product/cyclopsActions').submitNewCyclopsOrganizationRequest,
  setCyclopsActiveOrganizationRequest: require('./product/cyclopsActions').setCyclopsActiveOrganizationRequest,
  clearCyclopsActiveOrganizationRequest: require('./product/cyclopsActions').clearCyclopsActiveOrganizationRequest,
  editCyclopsOrganizationRequest: require('./product/cyclopsActions').editCyclopsOrganizationRequest,
  deleteCyclopsOrganizationRequest: require('./product/cyclopsActions').deleteCyclopsOrganizationRequest,
  uploadCyclopsOrganizationVodRequest: require('./product/cyclopsActions').uploadCyclopsOrganizationVodRequest,
  updateCyclopsOrganizationCDNSettingsRequest: require('./product/cyclopsActions').updateCyclopsOrganizationCDNSettingsRequest,
  // cyclopsActions - Livestreams
  initiateCyclopsLivestreamsRequest: require('./product/cyclopsActions').initiateCyclopsLivestreamsRequest,
  clearCyclopsLivestreamsRequest: require('./product/cyclopsActions').clearCyclopsLivestreamsRequest,
  submitNewCyclopsLivestreamRequest: require('./product/cyclopsActions').submitNewCyclopsLivestreamRequest,
  editCyclopsLivestreamRequest: require('./product/cyclopsActions').editCyclopsLivestreamRequest,
  deleteCyclopsLivestreamRequest: require('./product/cyclopsActions').deleteCyclopsLivestreamRequest,
  initiateCyclopsLivestreamDetailsRequest: require('./product/cyclopsActions').initiateCyclopsLivestreamDetailsRequest,
  clearCyclopsLivestreamDetailsRequest: require('./product/cyclopsActions').clearCyclopsLivestreamDetailsRequest,
  startOrStopCyclopsLivestreamRequest: require('./product/cyclopsActions').startOrStopCyclopsLivestreamRequest,
  // cyclopsActions - Piracy Streams
  initiateCyclopsPiracyStreamsRequest: require('./product/cyclopsActions').initiateCyclopsPiracyStreamsRequest,
  clearCyclopsPiracyStreamsRequest: require('./product/cyclopsActions').clearCyclopsPiracyStreamsRequest,
  killCyclopsPiracyStreamRequest: require('./product/cyclopsActions').killCyclopsPiracyStreamRequest,
  // cyclopsActions - Authed Domains
  initiateCyclopsAuthedDomainsRequest: require('./product/cyclopsActions').initiateCyclopsAuthedDomainsRequest,
  clearCyclopsAuthedDomainsRequest: require('./product/cyclopsActions').clearCyclopsAuthedDomainsRequest,
  submitNewCyclopsAuthedDomainRequest: require('./product/cyclopsActions').submitNewCyclopsAuthedDomainRequest,
  editCyclopsAuthedDomainRequest: require('./product/cyclopsActions').editCyclopsAuthedDomainRequest,
  deleteCyclopsAuthedDomainRequest: require('./product/cyclopsActions').deleteCyclopsAuthedDomainRequest,

  // OTHER
  // paymentActions
  clearPaymentTierRequest: require('./paymentActions').clearPaymentTierRequest,
  fetchPaymentTierRequest: require('./paymentActions').fetchPaymentTierRequest,
  checkoutRequest: require('./paymentActions').checkoutRequest,
  // redirectActions
  initiateRedirectRequest: require('./redirectActions').initiateRedirectRequest,
  clearRedirectRequest: require('./redirectActions').clearRedirectRequest,
  // faviconActions
  setFaviconLoading: require('./faviconActions').setFaviconLoading,
  setFaviconReady: require('./faviconActions').setFaviconReady,
  setFaviconDefault: require('./faviconActions').setFaviconDefault,
  // noticeActions
  closeNoticeRequest: require('./noticeActions').closeNoticeRequest,
  arriveNotice: require('./noticeActions').arriveNotice,
  // abortHelper
  resetAbortController: require('./aborterHelper').resetAbortController,
};
/* eslint-enable global-require */
